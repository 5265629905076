<template>
	<div id="cpm_landOrderDetail">
		<div class="el-content mb12" v-if="detail">
			<div class="order-steps">
				<a-steps :current="active">
				    <a-step title="买家下单1" :description="detail.create_time"/>
				    <a-step title="买家付款" :description="detail.pay_time"/>
				    <a-step title="商家发货" :description="detail.send_time"/>
				    <a-step title="订单完成" :description="detail.confirm_time"/>
				</a-steps>
			</div>
			<a-row class="order-info">
				<a-col  :span="12" style="border-right: 1px solid #f4f4f4;padding: 20px;">
					<div class="grid-content bg-purple">
						<div class="order-li">
							<div class="order-title">用户昵称 :</div><div  v-if="detail.account">{{detail.account.nickname}}</div>
						</div>
						<div class="order-li">
							<div class="order-title">真实姓名 :</div><div>{{detail.name}}</div>
						</div>
						<div class="order-li">
							<div class="order-title">联系电话 :</div><div>{{detail.mobile}}</div>
						</div>
						<div class="order-li">
							<div class="order-title">价格 :</div><div>￥{{detail.buy_price}}</div>
						</div>
						<div class="order-li">
							<div class="order-title">数量 :</div><div>x {{detail.buy_count}} {{detail.adopt.unit}}</div>
						</div>
						<div class="order-li">
							<div class="order-title">付款方式 :</div><div>{{$util.payMethod(detail.pay_method)}}</div>
						</div>
						<div class="order-li">
							<div class="order-title">买家备注:</div><div>{{detail.remark}}</div>
						</div>
						<div class="order-li">
							<div class="order-title">商家备注 :</div><div>{{detail.manager_remark}}</div>
						</div>
						<div class="order-li">
							<div class="order-title">配送方式 :</div><div>快递</div>
						</div>
					</div>
				</a-col>
				<a-col :span="12" style="padding: 20px;">
					<div class="grid-content bg-purple-light">
						<div class="order-li">
							<div class="order-title">订单编号 :</div><div>{{detail.order_number}}</div>
						</div>
						<div class="order-li" >
							<div class="order-title">订单状态 :</div>
							<div>
								<span class="order-status">{{$status.adoptOrderStatus(detail)}}</span>
							</div>
						</div>
						<div class="order-li" >
							<com-cashback-status :order-id="detail.id" :order-type="3"></com-cashback-status>
						</div>
						<div class="order-li" style="margin: 30px 0 10px 0;">
							<span  v-has="{action:'wxapp_adopt_order_remark',plat:isShop}">
								<com-set-remark
									btn-text="[备注]"
									btn-type="link"
									btn-size="small"
									remark-type="adopt-order"
									postApi="doSomethingByOrder"
									:remarkValue="detail.manager_remark"
									:params="{
										pk:detail.id,
										type:5 ,
										order_type:10
									}" @success="()=>{getAdoptOrderDetail()}">
								</com-set-remark>
							</span>
							<a-button type="link" @click="printAdoptOrder(detail.id)">[打印订单]</a-button>
							<a-button type="link" @click="aorState.show = true"
                                      v-if="detail.pay_status ==1 && detail.is_confirm !=1 && detail.is_refund == 0">
                                [退款]
                            </a-button>
							<com-pay-result :id="detail.id" :type="3"></com-pay-result>
							<a-button type="link" @click="downloadContract(detail.id)">[下载合同]</a-button>
							<com-select-gbdevice :order="detail" :set-type="3"></com-select-gbdevice>
						</div>
						<div class="f12">友情提示： 如果无法进行发货，请及时联系买家进行妥善处理</div>
					</div>
				</a-col>
			</a-row>
		</div>

        <!--认养详细信息-->
        <div class="el-content aod-detail mb12" v-if="detail">
            <div class="aod-detail-title">认养信息</div>
            <a-row class="aod-detail-head" type="flex" justify="space-around" align="middle">
                <a-col :span="5">基本信息</a-col>
                <a-col :span="4">认养编号</a-col>
                <a-col :span="3">认养状态</a-col>
                <a-col :span="4">时间信息</a-col>
                <a-col :span="6">操作</a-col>
            </a-row>
            <a-row class="aod-detail-item"
                   type="flex"
                   justify="space-around"
                   align="middle"
                   v-for="(item,index) in detail.detail"
                   :key="index">
                <a-col :span="5">
                    <div class="adi-cover">
                        <a-image class="image" :src="item.cover"></a-image>
                        <div class="adi-info">
                            <p>{{ item.name }}</p>
                            <a-tag color="#00CC66" v-if="item.statistics.reside == 0">已配送完毕</a-tag>
                            <div v-else>
                                <a-tag color="#33CCCC" v-if="item.statistics.complete >0">已配送{{ item.statistics.complete}}期</a-tag>
                                <a-tag color="#999" v-else>未开始配送</a-tag>
                                <a-tag color="#FF3366">剩余{{ item.statistics.reside}}期</a-tag>
                            </div>
                        </div>
                    </div>
                </a-col>
                <a-col :span="4">
                    <span>{{item.adopt_number || '未分配' }}</span>
                    <span class="opeartionSpan" @click="showAdoptNumber(item)">[修改]</span>
                </a-col>
                <a-col :span="3">
                    <div v-has="{action:'wxapp_adopt_order_change_status',plat:isShop}"
                         v-if="item.adoption_status==1">待分配
                        <span class="opeartionSpan" @click="changeAdoptStatus(item.id,2)">[认养中]</span>
                    </div>
                    <div v-has="{action:'wxapp_adopt_order_change_status',plat:isShop}"
                         v-if="item.adoption_status==2">认养中
                        <span class="opeartionSpan" @click="changeAdoptStatus(item.id,3)">[已成熟]</span>
                    </div>
                    <div v-if="item.adoption_status==3">配送中</div>
                    <div v-if="item.adoption_status==4">已完成</div>
                </a-col>
                <a-col :span="4">
                    <p class="lh16"><span class="f12">认养时间：</span>{{ item.adopt_time }}</p>
                    <p class="lh16">
                        <span class="f12">成熟时间：</span>{{ item.ripe_time }}
                        <a-button type="link" @click="showRipeTime(item)">[更新]</a-button>
                    </p>
                </a-col>
                <a-col :span="6">
                    <a-space>
                        <a-button
                            v-has="{action:'wxapp_adopt_order_status',plat:isShop}"
                            size="small"
                            @click="showAdoptStatusList(item.id)">状态跟踪
                        </a-button>
                        <a-button
                            v-has="{action:'wxapp_adopt_order_status_add',plat:isShop}"
                            size="small"
                            @click="showAddAdoptStatus(item,detail.adoption_id)">新增状态
                        </a-button>
                        <a-button
                            v-has="{action:'wxapp_adopt_order_add_cycle',plat:isShop}"
                            size="small"
                            @click="showAddDelivery(item.order_id,item.id)">添加周期
                        </a-button>
                        <com-set-live :custom="true"
                                      :extra="{id:item.id}"
                                      :ids="item.liveids"
                                      type="adopt"
                                      @success="getAdoptOrderDetail"
                                      v-has="{action:'wxapp_adopt_order_set_live',plat:is_shop}"
                        >
                            <a-button size="small">设置监控</a-button>
                        </com-set-live>
                    </a-space>
                </a-col>
                <!--周期信息-->
                <a-col :span="24" class="adi-cycle">
                    <div class="adi-cycle-con" :class="item.show_delivery ? 'adi-cycle-active' :''">
                        <a-table row-key="id"
                                 :pagination="false"
                                 :data-source="item.delivery"
                                 :columns="[
                            {title:'每期编号',dataIndex:'cycle_number'},
                            {title:'物流信息',dataIndex:'express',slots:{customRender:'express'}},
                            {title:'配送时间',dataIndex:'time',slots:{customRender:'time'}},
                            {title:'收货信息',dataIndex:'get',slots:{customRender:'get'}},
                            {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
                        ]" >
                            <template #express="{record}">
                                <div v-if="record.express_company">{{record.express_company}}</div>
                                <div v-if="record.express_no">{{record.express_no}}</div>
                                <div v-if="record.runing">
                                    <a-tooltip >
                                        <template #title>
                                            <template v-if="record.runing.run_type == 'dada'">
                                                <div>配送员：{{record.runing.dm_name}}</div>
                                                <div>配送员电话：{{record.runing.dm_mobile}}</div>
                                            </template>
                                            <template v-else>
                                                <div v-if="record.runing.persion">配送员：{{record.runing.persion.name}}</div>
                                                <div v-if="record.runing.persion">配送员电话：{{record.runing.persion.mobile}}</div>
                                            </template>
                                            <div>取货时间：{{record.runing.pickup_time || '--'}}</div>
                                            <div>送达时间：{{record.runing.confirm_time || '--'}}</div>
                                            <div v-if="record.runing.cancel_reason">取消理由：{{record.runing.cancel_reason}}</div>
                                        </template>
                                        <a-tag color="#999" v-if="record.runing.run_type == 'ziying'">商家配送</a-tag>
                                        <a-tag color="#999" v-if="record.runing.run_type == 'dada'">达达配送</a-tag>
                                    </a-tooltip>

                                    <p v-if="record.runing.persion">配送员：{{record.runing.persion.name}} ,{{record.runing.persion.mobile}}</p>
                                    <div v-if="record.runing.cancel_reason" style="color: red;">取消理由：{{record.runing.cancel_reason}}</div>
                                </div>
                            </template>
                            <template #time="{record}">
                                <div>{{record.send_time || "未定"}}</div>
                            </template>
                            <template #get="{record}">
                                <div>{{record.name}} / {{record.phone }}  {{record.address}}</div>
                            </template>
                            <template #action="{record}">
                                <a-space>
                                    <template v-if="record.delivery_status == 0 " v-has="{action:'wxapp_adopt_order_send',plat:isShop}">
                                        <com-deliver-goods
                                            v-if="(record.id == item.statistics.cycle_id )  && detail.is_refund !=2"
                                            btn-type="primary"
                                            btn-size="small"
                                            btn-text="立即发货"
                                            :id="record.id"
                                            :type="3"
                                            :is-shop="isShop"
                                            @success="()=>{getAdoptOrderDetail()}">
                                        </com-deliver-goods>
                                        <a-button v-else type="primary" disabled size="small">立即发货</a-button>
                                    </template>
                                    <a-button
                                        v-has="{action:'wxapp_adopt_order_confirm',plat:isShop}"
                                        v-if="record.delivery_status == 1 "
                                        @click="changeAdoptOrderStatus(record.id,3)"
                                        size="small">确认收货
                                    </a-button>
                                    <span v-if="record.delivery_status == 2" style="color: #00CC66">已完成</span>
                                    <span v-has="{action:'wxapp_order_logistics',plat:isShop}" v-if="record.delivery_status>=1">
                                        <com-logistics :order-type="3"
                                                       :pk="record.id"
                                                       btn-size="small"
                                                       btn-text="查看物流">
                                        </com-logistics>
                                    </span>
                                    <template v-if="record.delivery_status==0 && isShop == 0 && (record.id == item.statistics.cycle_id )  && detail.is_refund !=2">
                                        <com-print-face-order
                                            btn-type="primary"
                                            btn-size="small"
                                            :type="3"
                                            :order-id="record.id"
                                            @close="getAdoptOrderDetail()">
                                        </com-print-face-order>
                                    </template>
                                </a-space>
                            </template>
                        </a-table>
                    </div>

                    <div class="expand-cycle" @click="showDelivery(index)">
                        点击查看周期配送信息
                        <i class="ri-lg ri-top" :class="item.show_delivery ?'ri-arrow-up-s-line':'ri-arrow-down-s-line'"></i>
                    </div>
                </a-col>
            </a-row>
        </div>

		<div class="el-content" v-if="detail">
			<div class="kd-pacel" style="margin-top: 24px;">订单日志</div>
			<a-table :pagination="false" row-key="id" :data-source="aolState.list" size="small" :columns="[
				{title:'ID',dataIndex:'id'},
				{title:'操作说明',dataIndex:'content'},
				{title:'操作时间',dataIndex:'create_time'},
			]" >
			</a-table>
		</div>

		<a-modal title="修改认养编号"
                 width="500px"
                 v-model:visible="eanState.show"
                 @ok="saveAdoptNumber"
                 @cancel="eanState.show = false">
			<a-input v-model:value="eanState.adopt_number"></a-input>
		</a-modal>

		<a-modal title="更新认养预计成熟时间"
                 v-model:visible="eartState.show"
                 width="500px"
                 @ok="saveRipeTime"
                 @cancel="eartState.show = false">
			<a-date-picker v-model:value="eartState.ripe_time" />
		</a-modal>

        <a-modal title="状态跟踪"
                 v-model:visible="astState.show"
                 width="700px"
                 :footer="null"
                 @cancel="astState.show = false" >
			<a-timeline>
			    <a-timeline-item v-for="(item,index) in astState.list" :key="index">
					<div>
						<a-button size="small" type="link" @click="deleteAdoptStatus(item.id)">删除</a-button>
						{{item.description}}
					</div>
					<div class="cpl-imgs">
						<a-image style="width: 50px;height: 50px;margin-right: 12px;"
						v-for="(val,ind) in item.src_show" :key='ind' :src="val" />
					</div>
				</a-timeline-item>
			</a-timeline>
		</a-modal>

		<a-modal title="新增状态信息跟踪"
                 width="700px"
                 v-model:visible="astState.showAdd"
                 @ok="saveAdoptStatus"
                 @cancel="astState.showAdd= false">
			<a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
				<a-form-item label="信息说明">
					<a-input v-model:value="astState.form.remark"></a-input>
				</a-form-item>
				<a-form-item label="生长阶段">
					<a-select v-model:value="astState.form.maturity">
						<a-select-option value="">请选择生长阶段</a-select-option>
						<a-select-option value="报名中">报名中</a-select-option>
						<a-select-option value="认养中">认养中</a-select-option>
						<a-select-option value="已成熟">已成熟</a-select-option>
						<a-select-option value="配送中">配送中</a-select-option>
						<a-select-option value="已完成">已完成</a-select-option>
					</a-select>
				</a-form-item>
				<a-form-item label="工单类型">
					<a-cascader
						v-model:value="astState.cascaderValue"
						:options="astState.adoptServer"
						:fieldNames="{
							value:'id',
							label:'title',
							children:'details'
						}"
						placeholder="工单类型"
						@change="changeServer"
					/>

				</a-form-item>
				<a-form-item label="图片信息">
					<kd-img-select :mutil="true"
                                   :src="astState.form.images"
                                   @success="(url)=>{ astState.form.images = url }">
                    </kd-img-select>
				</a-form-item>
			</a-form>
		</a-modal>

		<a-modal title="添加周期信息"
                 width="700px"
                 v-model:visible="aodState.show"
                 @ok="saveAdoptDelivery"
                 @cancel="aodState.show= false"
        >
			<a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
				<a-form-item label="收货人">
					<a-input v-model:value="aodState.form.name"></a-input>
				</a-form-item>
				<a-form-item label="联系电话">
					<a-input v-model:value="aodState.form.phone"></a-input>
				</a-form-item>
				<a-form-item label="收货地址">
					<a-input v-model:value="aodState.form.address" disabled></a-input>
				</a-form-item>
			</a-form>
		</a-modal>

		<a-modal title="退款"
                 width="400px"
                 v-model:visible="aorState.show"
                 @ok="sureRefund(detail.id)"
                 @cancel="aorState.show= false">
			<a-input v-model:value="aorState.price"></a-input>
		</a-modal>

	</div>
</template>

<script>
import comCashbackStatus from '@/components/miniapp/com-cashback-status.vue'
import comSetRemark from '@/components/miniapp/com-set-remark.vue'
import comPayResult from '@/components/miniapp/com-pay-result.vue'
import comDeliverGoods from '@/components/miniapp/com-deliver-goods.vue'
import comLogistics from '@/components/miniapp/com-logistics.vue'
import comPrintFaceOrder from '@/components/miniapp/com-print-face-order.vue'
import { reactive, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import adoptModel from '@/api/adopt.js'
import comSelectGbdevice from '@/components/layout/live/com-select-gbdevice'
import comSetLive from '@/components/miniapp/com-set-live.vue'
import {
    useEditAdoptRipeTime,
    useEditAdoptNumber,
    useAdoptStatusTail,
    useAdoptOrderDelivery,
    useAdoptOrderRefund ,
    useChangeAdoptOrderStatus,
    useAdoptOrderLog
} from '@/models/adopt'

export default{
	name:'page-order-detail',
	components:{
		comCashbackStatus,
		comSetRemark,
		comPayResult,
		comDeliverGoods,
		comLogistics,
		comPrintFaceOrder,
		comSelectGbdevice,
        comSetLive
	},
	props:{
		isShop:Number
	},
	setup(){
		const _d = reactive({
			active:1,
			detail:null,	//订单详情
		})

		const opt = useRoute().query
		if( opt.order_id ) getAdoptOrderDetail()

        //配送周期操作
        let { aodState,showDelivery,showAddDelivery,saveAdoptDelivery} = useAdoptOrderDelivery(_d,getAdoptOrderDetail)

		function getAdoptOrderDetail(){
			adoptModel.getAdoptOrderDetail(opt.order_id,res=>{
				if( res.pay_status  == 0 ) _d.active =1
				if( res.pay_status  == 1 ) _d.active =2
				if( res.order_status  == 3 ) _d.active =3
				if( res.is_confirm  == 1 ) _d.active =4

				_d.detail = res
                showDelivery(-1)
			})
		}

		//修改认养预计成熟时间
        let { eartState,showRipeTime,saveRipeTime } = useEditAdoptRipeTime(getAdoptOrderDetail)

        //修改认养编号
        let eanState = useEditAdoptNumber(getAdoptOrderDetail)

        //认养状态跟踪
        let astState = useAdoptStatusTail()

        //订单退款
        let { aorState,sureRefund } = useAdoptOrderRefund(getAdoptOrderDetail)

        //修改订单状态
        let useAdoptOrderStatus = useChangeAdoptOrderStatus(null,getAdoptOrderDetail)

        let { aolState } = useAdoptOrderLog(opt.order_id)

		return{
			...toRefs(_d),
			getAdoptOrderDetail,
            eartState,showRipeTime,saveRipeTime,

            aodState,showDelivery,showAddDelivery,saveAdoptDelivery,
            aorState,sureRefund,
            aolState,
            ...eanState,
            ...astState,
            ...useAdoptOrderStatus
		}
	},
}

//571
</script>
<style lang="scss">
.aod-detail{
    p{
        margin-bottom: 6px;
    }
    &-title{
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 24px;
    }
    .aod-detail-item{
        border: 1px solid #f4f4f4;
        padding: 12px 0;
        margin-bottom: 12px;
    }
    .adi-cover{
        display: flex;
        .image{
            width: 60px;
            margin-right: 12px;
        }
        .adi-info{
            p{
                line-height: 12px;
                margin-bottom: 12px;
            }
        }
    }

    &-head{
        border: 1px solid #f4f4f4;
        padding: 6px 0;
        font-weight: bold;
        margin-bottom: 12px;
    }

    .adi-cycle{
        text-align: center;

        &-con{
            max-height: 0;
            transition: max-height ease-out 0.5s;
            overflow: hidden;
            overflow-y: auto;
        }
        &-active{
            max-height: 800px;
            transition: max-height ease-in 0.5s;
        }
    }
    .expand-cycle{
        height: 30px;
        line-height: 30px;
        cursor: pointer;
        color: #00CC66;
        padding-top: 6px;
    }
}

.order-steps{
	width: 80%;
	margin: auto;
}
.order-info{
	border: 1px solid #f4f4f4;
	width: 90%;
	margin: auto;
	margin-top: 20px;
	font-size: 12px;
	line-height: 30px;
}
.order-li{
	display: flex;
}
.order-li .order-title{
	width: 70px;
	color: #868686;
}
.order-status{
	color: orange;
	font-size: 20px;
	font-weight: bold;
}

.opeartionSpan{
	color: #0074D9;
	cursor: pointer;
}
</style>
