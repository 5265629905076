<template>
    <div class="com-set-live">
        <div v-if="custom" @click="showLive">
            <slot />
        </div>
        <a-modal v-model:visible="show" title="设置监控" @cancel="show = false" @ok="setLive" width="500px">
			<a-select v-model:value="live_ids" mode="multiple" placeholder="请选择监控" style="width:400px">
                <a-select-option v-for="item in live" :key="item.id" :value="item.id">
                    {{item.title}}
                </a-select-option>
            </a-select>
		</a-modal>
    </div>
</template>
<script>
import { reactive, toRefs, watch } from 'vue'
import liveModel from '@/api/live.js'
export default {
    components:{
        name:'com-set-live'
    },
    props:{
        custom:{
            type:Boolean,
            default:false
        },
        type:{  //板块标志 adopt 认养 goods商品
            type:String,
            default:"goods"
        },

        ids:{   //已绑定的数据
            type:[Array,Object,String],
        },
        extra:{     //设置额外的参数
            type:Object
        }
    },
    setup(props,context) {
        const _d = reactive({
            show:false,
            live_ids:[],
            live:[],    //所有监控信息
        })


        function setLive(){
            let exData = props.extra
            if( typeof exData.levels == 'string'){
                exData.levels = JSON.parse(exData.levels)
            }
            liveModel.allocationLive(props.type,_d.live_ids,exData,()=>{
                _d.show = false
                context.emit("success")
            })
        }

        function showLive(){
            if( _d.live.length == 0 ){
                liveModel.getLiveList(1,999,null,res=>{
                    _d.live = res.list
                })
            }
            _d.show = true
            if( props.ids ){
                if( typeof props.ids =="string"){
                    _d.live_ids =JSON.parse(props.ids)
                }
            }
        }

        return {
            ...toRefs(_d),
            setLive,
            showLive
        }
    },
}
</script>
